import * as React from "react";
import { StaticImage } from 'gatsby-plugin-image';
import { PageProps } from "gatsby";
import { LandingPageLayout } from '../components/layout';
import { button, buttonIcon } from "./index.module.css";
import InstagramIcon from '../components/icons/InstagramIcon';

const IndexPage = ({ path }: PageProps) => {
  return (
    <LandingPageLayout pageTitle="Landing Page">
      <StaticImage
        alt="Logo"
        src="../images/minihauslogo_medium.png"
      />
      <button className={button} role="button" onClick={() => {
        location.href = 'https://www.instagram.com/minihaus3d/?hl=de';
      }}>
        <InstagramIcon className={buttonIcon} />
        Besuch uns auf Instagram
      </button>
    </LandingPageLayout>
  )
}

export default IndexPage
